/* contact form  */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}
:root {
	--grey: #2e2e2e;
	--black: #000;
	--white: #f2f2f2;
  
	--yellow: #e1a70a;
	--font-size: 1.4rem;
	--font-weight: 400;
  }
body {
	background: #ffffff;
	color: rgb(0, 0, 0);
}

