.dashboard{
    font-size: 14px;
    height: 100vh;
    background: var(--white);
    overflow: hidden;
}
.dashboard .logo-box {
    background-color: #f2f2f2;
}

.dashboard .logo-img {
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard .logo-img img {
    width: 9%;
    padding: 20px 0;
}

.dashboard .usericon i{
    font-size: 5em;
    padding-bottom: 10px;
}
.dashboard .user-info{
    padding: 0 50px;
    text-align: justify;
}
.dashboard .left-box {
    text-align: center;
    margin-top: 30px;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    overflow-x: auto;
}
.big-icon{
    font-size: 3em;
}
.dashboard-form{
    background-color: var(--white) !important;
    box-shadow: none !important;
    border: none !important;
}
.dashboard-form-money-feild{
    margin: 10px 0 40px 0 !important;
}
.dashboard-form-money-feild i{
    padding-top: 6px !important;
    font-size: 0.9em;
}
.right-box {
    background-color: #ffffff;
    height: 100vh;
    overflow: hidden;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    text-align: center;
}

.innerbox {
    margin-top: 20px;
}
.boxex {
    background-color: var(--white);
    margin-bottom: 40px;
    margin-right: 40px;
    padding: 20px 70px;
}
.total-amount-box{
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 101% !important;
    padding: 5px;
}
@media (max-width:750px) {
    .right-box{
        text-align: center;
    }
    .boxex {
        background-color: var(--white);
        margin-bottom: 40px;
        margin-right: 0px;
        padding: 20px 70px;
    }
    .dashboard{
        overflow: visible;
    }
    .right-box {
        overflow: visible;
    }
}